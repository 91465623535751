body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #333333;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 20;
  border-radius: 5px;
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes mymove {
  from {
    right: 100%;
  }
  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: 100%;
  }
  to {
    left: 0px;
  }
}

.menu-text--active {
  -webkit-animation: mymove 100ms;
          animation: mymove 100ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 180px;
  height: 500px;
  display: flex;
  background-color: #ffff;
  text-decoration: none;
  border-radius: 0 0 15px 0;
}

.menu-text--inactive {
  display: none;
}

@-webkit-keyframes open {
  0% {
    opacity: 0;
    left: 45px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes open {
  0% {
    opacity: 0;
    left: 45px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@-webkit-keyframes close {
  0% {
    opacity: 0.4;
    left: -0px;
  }
  100% {
    opacity: 0;
    left: -45px;
  }
}

@keyframes close {
  0% {
    opacity: 0.4;
    left: -0px;
  }
  100% {
    opacity: 0;
    left: -45px;
  }
}

@media (max-width: 576px) {
  @-webkit-keyframes mymove {
    from {
      top: -300px;
    }
    to {
      top: 0px;
    }
  }
  @keyframes mymove {
    from {
      top: -300px;
    }
    to {
      top: 0px;
    }
  }

  .menu-text--active {
    -webkit-animation: mymove 300ms;
            animation: mymove 300ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    /* max-width: 100%; */
    height: 300px;
    display: flex;
    background-color: #ffff;
    text-decoration: none;
    border-radius: 0px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 1px 0.5px 0 rgba(0, 0, 0, 0.19);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

.App {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.portfolio-container {
  max-width: 1024px;
  margin: 0 auto;
}
/* 
.header-view {
  width: 100%;
} */

.headShot {
  left: 0px;
  top: 0px;
  z-index: -10;
  width: 100vw;
  height: 100vh;
}

.testing {
  display: block;
}

.navMenu {
  /* border: 1px solid orange; */
  /* display: flex; */
  position: fixed;
  z-index: 20;
  width: 100%;
}

.nav-subContainer {
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navLinks {
  text-decoration: none;
  color: #a3a3a3;
  padding: 5px;
  font-size: 2.5rem;
  /* line-height: 50px; */
  margin: 15px 0;
}

.parallax-headshot {
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* background-color: white; */
  opacity: 1;
  width: 420px;
  height: 250px;
  border-radius: 15px;
}

.profile-name h1 {
  color: white;
  font-weight: bold;
  font-size: 5.5rem;
  font-family: 'Informal Roman', 'Garamond', sans-serif;
  opacity: 1;
}
.profile-name h2 {
  color: white;
  font-size: 8rem;
  font-weight: bold;
  font-family: 'Sitka Heading', 'Century Schoolbook', sans-serif;
}

.experience-tabs {
  /* border: 1px solid yellow; */
  margin: 0 auto;
  max-width: 1024px;
  min-width: 520px;
}

.experience-tabs h1 {
  font-size: 3.6rem;
  margin: 50px auto;
  font-family: 'Lato Light', sans-serif;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  width: 200px;
  padding: 6px;
}

.exp-description {
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: left;
}

.role {
  display: flex;
  /* justify-content: space-between; */
  margin: 60px 0;
  font-family: 'Lato Light', sans-serif;
}

.company-img {
  width: 250px;
  height: 250px;
  border-radius: 20px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2), 0 1px 0.5px 0 rgba(0, 0, 0, 0.19);
}

.company-info-desc {
  text-align: left;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.role-title {
  font-size: 2.5rem;
  color: #0d5fe2;
}

.location {
  font-size: 2rem;
  margin: 10px 0;
  font-style: italic;
}

.project-tabs h1 {
  /* border: 1px solid green; */
  font-size: 3.6rem;
  margin: 50px auto;
  font-family: 'Lato Light', sans-serif;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  width: 200px;
  padding: 6px;
}

.project-tabs {
  width: 100%;
}

.project-snippets {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 40px 0;
}

.each-project {
  padding: 0 15px;
}

.project-snippet {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 0.5px 0 rgba(0, 0, 0, 0.19);
}

.description {
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: left;
  padding: 10px;
  width: 300px;
  font-style: italic;
  font-family: 'Lato Light', sans-serif;
}

.footer {
  /* border: 1px solid red; */
  width: 100%;
  margin: 20px 0 0 0;
  background-color: #1a202b;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.footer-quote{
  padding: 10px 0 0 0;
}
.footer-quote h1 {
  font-family: 'Lato Light', sans-serif;
  font-size: 1.5rem;
  font-weight: lighter;
  margin: 20px 0;
  color: white;
  text-align: right;
}

.contact-info {
  align-items: center;
  display: flex;
  margin: 20px 0;
}
.social-media svg {
  width: 100px;
  height: 100px;
  background-color: white;
  text-align: right;
  font-style: bold;
  border-radius: 20px;
}
.social-media {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  align-items: center;
  margin: 0 25px;
}

@media (max-width: 1400px) {
  /* @viewport {

  } */
  .portfolio-container {
    padding: 0 20px;
  }

  .project-tabs {
    width: 100%;
  }

  .project-snippets {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 40px 0;
    justify-content: center;
  }

  .project-snippet {
    margin: 10px 0;
  }

  .footer {
    /* border: 1px solid red; */
    flex-direction: column;

    /*    padding: 20px; */
  }

  .footer-quote h1 {
    text-align: center;
  }

  .contact-info {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px 0;
    align-content: space-around;
    margin: 0 auto;
    width: 50%;
  }
  .social-media svg {
    width: 80px;
    height: 80px;
    background-color: white;
    text-align: right;
    font-style: bold;
    border-radius: 20px;
  }
  .social-media {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    align-items: center;
    margin: 0;
    /* padding: 20px 0; */
  }
}

@media (max-width: 720px) {
  .App {
    width: 100%;
    /* min-width: 480px; */
    margin: 0 auto;
    /* display: flex; */
    /* width: 100%; */
  }

  /* .App-Container {
    width: 100vw;
    
  } */

  .headShot {
    width: 100%;
  }

  .profile-name{
    width: 100%;
  }

  .parallax-container {
    width: 100%;
    margin: 0 auto;
  }

  .header-height {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .project-tabs {
    width: 100%;

    /* padding: 20px; */
  }
  .project-tabs h1{
    width: 100%;
    max-width: 200px;


    /* padding: 20px; */
  }

  .project-snippets {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 40px 0;
    width: 100%;
  }

  .each-project {
    justify-content: center;
    padding: 0;
    width: 100%;
  }

  .project-snippet {
    margin: 10px 0;
    width: 100%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
  }

  .description{
    width: 100%;
    max-width: 400px; 
    justify-content: center;
    margin: 0 auto;
  }

  .experience-tabs {
    width: 100%;
    min-width: 0;
  }
  .experience-tabs h1{
    width: 80%;
  }

  .role {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }
  .company-img {
    width: 100%;
    height: auto !important;
    max-width: 400px;
    max-height: 400px;
    border-radius: 20px;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2),
      0 1px 0.5px 0 rgba(0, 0, 0, 0.19);
    margin: 0 0 10px 0;
  }
  
  .company-info-desc {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    /* text-align: justify; */
  }


  .footer {
    margin: 0 auto;
    width: 100%;
  }

  .company-info {
    width: 400px;
    margin: 0 auto;
    width: 80%;
  }

  .social-media{
    margin: 5px;
  }
  .social-media svg{
    padding: 5px;
  }
}



#loadOverlay {
  display: none;
}

