.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #333333;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 20;
  border-radius: 5px;
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes mymove {
  from {
    right: 100%;
  }
  to {
    left: 0px;
  }
}

.menu-text--active {
  animation: mymove 100ms;
  animation-fill-mode: both;
  animation-delay: 200ms;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 180px;
  height: 500px;
  display: flex;
  background-color: #ffff;
  text-decoration: none;
  border-radius: 0 0 15px 0;
}

.menu-text--inactive {
  display: none;
}

@keyframes open {
  0% {
    opacity: 0;
    left: 45px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes close {
  0% {
    opacity: 0.4;
    left: -0px;
  }
  100% {
    opacity: 0;
    left: -45px;
  }
}

@media (max-width: 576px) {
  @keyframes mymove {
    from {
      top: -300px;
    }
    to {
      top: 0px;
    }
  }

  .menu-text--active {
    animation: mymove 300ms;
    animation-fill-mode: both;
    animation-delay: 200ms;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    /* max-width: 100%; */
    height: 300px;
    display: flex;
    background-color: #ffff;
    text-decoration: none;
    border-radius: 0px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 1px 0.5px 0 rgba(0, 0, 0, 0.19);
  }
}
